import React, { useState, createContext, useEffect } from "react";
// import firebase from "../firebase";

const DatabaseContext = createContext();

export const DatabaseProvider = ({ children }) => {

  return (
    <DatabaseContext.Provider value={{}}>{children}</DatabaseContext.Provider>
  );
};

export default DatabaseContext;
