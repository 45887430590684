import React, { useState, useEffect } from "react";
import styles from "./BookDashboard.module.scss";
import Slider from "../../components/NetflixSlider";
import { firestore } from "../../firebase";
import books from "../../data/books.json";
import Footer from "../../components/Footer";

const BookDashboard = () => {
  const filterBooksByCategory = (categorySlider) => {
    return books.books.filter((book) => {
      if (book.category === categorySlider) return book;
    });
  };

  const scrollFunc = () =>
    document.getElementById("scrollPoint").scrollIntoView();

  // const fetchBooks = () => {
  //   console.log("book fetch");
  //   firestore
  //     .collection("products")
  //     .doc("books")
  //     .get()
  //     .then((doc) => {
  //       const retrievedItems = doc.data().bookData;
  //       setBooks(retrievedItems);
  //     });
  // };

  useEffect(() => {
    scrollFunc();
  }, []);

  return (
    <section className={styles.bookDashboard} id="scrollPoint">
      <h2 className={styles.sliderTitle}>World War I</h2>
      <Slider>
        {filterBooksByCategory("ww1").map((book) => (
          <Slider.Item product={book} key={book.id}>
            item1
          </Slider.Item>
        ))}
      </Slider>

      <h2 className={styles.sliderTitle}>World War II</h2>
      <Slider>
        {filterBooksByCategory("ww2").map((book) => (
          <Slider.Item product={book} key={book.id}>
            item1
          </Slider.Item>
        ))}
      </Slider>

      <h2 className={styles.sliderTitle}>Asia</h2>
      <Slider>
        {filterBooksByCategory("asia").map((book) => (
          <Slider.Item product={book} key={book.id}>
            item1
          </Slider.Item>
        ))}
      </Slider>

      <h2 className={styles.sliderTitle}>British</h2>
      <Slider>
        {filterBooksByCategory("british").map((book) => (
          <Slider.Item product={book} key={book.id}>
            item1
          </Slider.Item>
        ))}
      </Slider>

      <h2 className={styles.sliderTitle}>German</h2>
      <Slider>
        {filterBooksByCategory("german").map((book) => (
          <Slider.Item product={book} key={book.id}>
            item1
          </Slider.Item>
        ))}
      </Slider>

      <h2 className={styles.sliderTitle}>Napoleon</h2>
      <Slider>
        {filterBooksByCategory("napoleon").map((book) => (
          <Slider.Item product={book} key={book.id}>
            item1
          </Slider.Item>
        ))}
      </Slider>

      <h2 className={styles.sliderTitle}>USA</h2>
      <Slider>
        {filterBooksByCategory("usa").map((book) => (
          <Slider.Item product={book} key={book.id}>
            item1
          </Slider.Item>
        ))}
      </Slider>

      <h2 className={styles.sliderTitle}>Zulu War</h2>
      <Slider>
        {filterBooksByCategory("zulu").map((book) => (
          <Slider.Item product={book} key={book.id}>
            item1
          </Slider.Item>
        ))}
      </Slider>

      <h2 className={styles.sliderTitle}>Miscellaneous</h2>
      <Slider>
        {filterBooksByCategory("misc").map((book) => (
          <Slider.Item product={book} key={book.id}>
            item1
          </Slider.Item>
        ))}
      </Slider>
      <Footer />
    </section>
  );
};

export default BookDashboard;
