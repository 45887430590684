import React from "react";
import IconCross from "./../Icons/IconCross";
import "./Content.scss";
import Roku from "../../assets/roku.png";
import Tubi from "../../assets/tubi.png";
import AmazonUK from "../../assets/amazonUK.png";
import AmazonUSA from "../../assets/amazonUSA.png";

const Content = ({ product, onClose }) => (
  <div className="content">
    <div className="content__background">
      <div className="content__background__shadow" />
      <div
        className="content__background__image"
        style={{ "background-image": `url(${product.imageBg})` }}
      />
    </div>
    <div className="content__area">
      <div className="content__area__container">
        <div className="content__title">{product.title}</div>
        <div className="content__description">{product.description}</div>

        <div className="content__linkList">
          {product.amazonUk !== "" ? (
            <div>
              <a href={product.amazonUk} target="_blank">
                <button className="content__link">
                  <img className="content__roku" src={AmazonUK} />
                </button>
              </a>
            </div>
          ) : (
            ""
          )}

          {product.amazonUs && product.amazonUs !== "" ? (
            <div>
              <a href={product.amazonUs} target="_blank">
                <button className="content__link">
                  <img className="content__roku" src={AmazonUSA} />
                </button>
              </a>
            </div>
          ) : (
            ""
          )}

          {product.tubi && product.tubi !== "" ? (
            <div>
              <a href={product.tubi} target="_blank">
                <button className="content__link">
                  <img className="content__tubi" src={Tubi} />
                </button>
              </a>
            </div>
          ) : (
            ""
          )}

          {product.roku && product.roku !== "" ? (
            <div>
              <a href={product.roku} target="_blank">
                <button className="content__link">
                  <img className="content__roku" src={Roku} />
                </button>
              </a>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <button className="content__close" onClick={onClose}>
        <IconCross />
      </button>
    </div>
  </div>
);

export default Content;
