import * as firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDce7YspUL9sAWyJA_vQyJweQftNEtV-bE",
  authDomain: "visions-of-war.firebaseapp.com",
  databaseURL: "https://visions-of-war.firebaseio.com",
  projectId: "visions-of-war",
  storageBucket: "visions-of-war.appspot.com",
  messagingSenderId: "630766470053",
  appId: "1:630766470053:web:2d4c2e97d099002cd1de4c",
  measurementId: "G-R02NEEWG29",
};

firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore();

export default firebase;
