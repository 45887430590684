import React, { useState, createContext, useEffect } from "react";
import books from "../data/books.json";
import films from "../data/films.json";
// import firebase from "../firebase";

const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [bookSearchResults, setBookSearchResults] = useState([]);
  const [filmSearchResults, setFilmSearchResults] = useState([]);

  let bookSearchArray = [];
  let filmSearchArray = [];

  const getSearchResults = (input) => {
    books.books.map((book) => {
      if (
        book.title.toLowerCase().includes(input) ||
        book.description.toLowerCase().includes(input)
      ) {
        bookSearchArray.push(book);
        setBookSearchResults(bookSearchArray);
      }
    });

    films.films.map((film) => {
      if (
        film.title.toLowerCase().includes(input) ||
        film.description.toLowerCase().includes(input)
      ) {
        filmSearchArray.push(film);
        setFilmSearchResults(filmSearchArray);
      }
    });
  };

  return (
    <SearchContext.Provider
      value={{
        bookSearchResults,
        filmSearchResults,
        setSearchTerm,
        setBookSearchResults,
        setFilmSearchResults,
        getSearchResults,
        searchTerm,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchContext;
