import React, { useEffect } from "react";
import styles from "./AboutUs.module.scss";
import Footer from "../Footer";

const AboutUs = () => {
  const scrollFunc = () =>
    document.getElementById("scrollPoint").scrollIntoView();

  useEffect(() => {
    scrollFunc();
  }, []);

  return (
    <>
      <section className={styles.aboutUs} id="scrollPoint">
        <article>
          <h1>ABOUT US</h1>
          <p>
            Visions of War is brought to you by our Emmy® Award winning team. We
            are dedicated to bringing you the very best in military history,
            through a series of incisive documentaries and archive films. With a
            library of companion books and e-Books, all available at the click
            of a button.
          </p>
        </article>
      </section>
      <Footer />
    </>
  );
};

export default AboutUs;
