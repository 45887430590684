import React, { useContext, useEffect } from "react";
import styles from "./SearchResults.module.scss";
import SearchContext from "../../context/SearchContext";
import Slider from "../../components/NetflixSlider";

const SearchResults = () => {
  const { searchTerm, bookSearchResults, filmSearchResults } = useContext(
    SearchContext
  );

  const scrollFunc = () =>
    document.getElementById("scrollPoint").scrollIntoView();

  useEffect(() => {
    scrollFunc();
  }, []);

  return (
    <section className={styles.searchResults} id="scrollPoint">
      <h2 className={styles.sliderTitle}>Showing results for "{searchTerm}"</h2>

      <h2 className={styles.sliderTitle}>Films</h2>

      {filmSearchResults.length !== 0 ? (
        <Slider>
          {filmSearchResults.map((film) => (
            <Slider.Item product={film} key={film.id}>
              item1
            </Slider.Item>
          ))}
        </Slider>
      ) : (
        <p className={styles.noMatch}>
          We don't have any books that match "{searchTerm}"
        </p>
      )}

      <h2 className={styles.sliderTitle}>Books</h2>

      {bookSearchResults.length !== 0 ? (
        <Slider>
          {bookSearchResults.map((book) => (
            <Slider.Item product={book} key={book.id}>
              item1
            </Slider.Item>
          ))}
        </Slider>
      ) : (
        <p className={styles.noMatch}>
          We don't have any films that match "{searchTerm}"
        </p>
      )}
    </section>
  );
};

export default SearchResults;
