import React from "react";
import styles from "./Banner.module.scss";
import normandy from "../../assets/normandy.jpg";
import logo from "../../assets/logo.png";
import arrows from "../../assets/arrows.png";

const Banner = () => {
  return (
    <section className={styles.banner}>
      <img className={styles.logo} src={logo} alt={logo} />
      <img
        className={styles.arrows}
        onClick={() => window.scrollBy(0, window.innerHeight + 5)}
        src={arrows}
        alt={arrows}
      />
      <img className={styles.bannerImg} src={normandy} alt={normandy} />
    </section>
  );
};

export default Banner;
