import React from "react";

// Providers
import { DatabaseProvider } from "./DatabaseContext";
import { SearchProvider } from "./SearchContext";

const index = ({ children }) => {
  return <SearchProvider>{children}</SearchProvider>;
};

export default index;
