import React from "react";
import styles from "./Footer.module.scss";
import { Link, navigate } from "@reach/router";

const Footer = () => {
  return (
    <section className={styles.footer}>
      <div className={styles.linkList}>
        <Link to={"../about-us"}>
          <p>About Us</p>
        </Link>
        <Link to={"../partners"}>
          <p>Our Partners</p>
        </Link>

        <a href="mailto:info@visionsofwar.tv">Contact Us</a>
        <Link to={"../privacy-policy"}>
          <p>Privacy Policy</p>
        </Link>
      </div>
      <p className={styles.copyright}>© Visions of War Limited 2020</p>
    </section>
  );
};

export default Footer;
