import React from "react";
import styles from "./Routes.module.scss";
import MovieDashboard from "../MovieDashboard";
import BookDashboard from "../BookDashboard";
import { Router, Redirect } from "@reach/router";
import Banner from "../../components/Banner";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import PrivacyPolicy from "../../components/PrivacyPolicy";
import searchResults from "../../containers/SearchResults";
import SearchResults from "../../containers/SearchResults";
import AboutUs from "../../components/AboutUs";
import Partners from "../../components/Partners";

const Routes = () => {
  return (
    <>
      <Banner />
      <NavBar />
      <Router>
        {/* <Redirect noThrow from="/" to="films" /> */}

        <MovieDashboard path={"films"} />
        <BookDashboard path={"books"} />
        <SearchResults path={"search-results"} />
        <PrivacyPolicy path={"privacy-policy"} />
        <AboutUs path={"about-us"} />
        <Partners path={"partners"} />
      </Router>
      {/* <Footer /> */}
    </>
  );
};

export default Routes;
