import React, { useEffect } from "react";
import styles from "./Partners.module.scss";
import Footer from "../Footer";
import tcdLogo from "../../assets/tcd.png";

const Partners = () => {
  const scrollFunc = () =>
    document.getElementById("scrollPoint").scrollIntoView();

  useEffect(() => {
    scrollFunc();
  }, []);
  return (
    <>
      <section className={styles.partners} id="scrollPoint">
        <article>
          <h1>OUR PARTNERS</h1>
          <div className={styles.partnerFlex}>
            <a
              className={styles.partnerLogo}
              target="_blank"
              href="https://www.totalcontentdigital.com/"
            >
              <img src={tcdLogo} alt="tcd logo" />
            </a>
            <p className={styles.partnerTxt}>
              We have worked closely with our distribution partners{" "}
              <a target="_blank" href="https://www.totalcontentdigital.com/">
                Total Content Digital
              </a>{" "}
              since 2014 to ensure the widest possible distribution for our
              films. They are available on a number of platforms worldwide
              including Amazon, Roku and Tubi.{" "}
            </p>
          </div>
        </article>
      </section>
      <Footer />
    </>
  );
};

export default Partners;
