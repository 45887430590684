import React, { useState, useEffect } from "react";
import styles from "./MovieDashboard.module.scss";
import Slider from "../../components/NetflixSlider";
import { firestore } from "../../firebase";
import films from "../../data/films.json";
import Footer from "../../components/Footer";

const MovieDashboard = () => {
  const filterFilmsByCategory = (categorySlider) => {
    return films.films.filter((film) => {
      if (film.category === categorySlider) return film;
    });
  };

  const scrollFunc = () =>
    document.getElementById("scrollPoint").scrollIntoView();

  // const fetchMovies = () => {
  //   console.log("movie fetch");
  //   firestore
  //     .collection("products")
  //     .doc("movies")
  //     .get()
  //     .then((doc) => {
  //       const retrievedItems = doc.data().movieData;
  //       setMovies(retrievedItems);
  //     });
  // };

  useEffect(() => {
    scrollFunc();
  }, []);

  return (
    <section className={styles.movieDashboard} id="scrollPoint">
      <h2 className={styles.sliderTitle}>World War I</h2>
      <Slider>
        {filterFilmsByCategory("ww1").map((film) => (
          <Slider.Item product={film} key={film.id}>
            item1
          </Slider.Item>
        ))}
      </Slider>

      <h2 className={styles.sliderTitle}>World War II</h2>
      <Slider>
        {filterFilmsByCategory("ww2").map((film) => (
          <Slider.Item product={film} key={film.id}>
            item1
          </Slider.Item>
        ))}
      </Slider>

      <h2 className={styles.sliderTitle}>World War II – Battlezone Series</h2>
      <Slider>
        {filterFilmsByCategory("ww2bz").map((film) => (
          <Slider.Item product={film} key={film.id}>
            item1
          </Slider.Item>
        ))}
      </Slider>

      <h2 className={styles.sliderTitle}>
        World War II – Decisive Battles of Hitler's War
      </h2>
      <Slider>
        {filterFilmsByCategory("ww2db").map((film) => (
          <Slider.Item product={film} key={film.id}>
            item1
          </Slider.Item>
        ))}
      </Slider>

      <h2 className={styles.sliderTitle}>World War II – Through German Eyes</h2>
      <Slider>
        {filterFilmsByCategory("ww2tge").map((film) => (
          <Slider.Item product={film} key={film.id}>
            item1
          </Slider.Item>
        ))}
      </Slider>

      <h2 className={styles.sliderTitle}>World War II – Scorched Earth</h2>
      <Slider>
        {filterFilmsByCategory("ww2se").map((film) => (
          <Slider.Item product={film} key={film.id}>
            item1
          </Slider.Item>
        ))}
      </Slider>

      <h2 className={styles.sliderTitle}>British</h2>
      <Slider>
        {filterFilmsByCategory("british").map((film) => (
          <Slider.Item product={film} key={film.id}>
            item1
          </Slider.Item>
        ))}
      </Slider>

      <h2 className={styles.sliderTitle}>USA</h2>
      <Slider>
        {filterFilmsByCategory("usa").map((film) => (
          <Slider.Item product={film} key={film.id}>
            item1
          </Slider.Item>
        ))}
      </Slider>

      <h2 className={styles.sliderTitle}>Vietnam</h2>
      <Slider>
        {filterFilmsByCategory("vietnam").map((film) => (
          <Slider.Item product={film} key={film.id}>
            item1
          </Slider.Item>
        ))}
      </Slider>

      <h2 className={styles.sliderTitle}>Miscellaneous</h2>
      <Slider>
        {filterFilmsByCategory("misc").map((film) => (
          <Slider.Item product={film} key={film.id}>
            item1
          </Slider.Item>
        ))}
      </Slider>
      <Footer />
    </section>
  );
};

export default MovieDashboard;
