import React, { useState, useContext } from "react";
import styles from "./NavBar.module.scss";
import { Link, navigate } from "@reach/router";
import SearchContext from "../../context/SearchContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";

const NavBar = () => {
  const {
    setSearchTerm,
    setBookSearchResults,
    setFilmSearchResults,
    getSearchResults,
    searchTerm,
  } = useContext(SearchContext);

  return (
    <section className={styles.navBar}>

      <div className={styles.fb}>
        <a href="https://www.facebook.com/visionsofwartv" target="_blank">
          <div>
            <FontAwesomeIcon icon={faFacebookSquare} />
          </div>
          <span>Like us on facebook!</span>
        </a>
      </div>
      <div className={styles.linkFlex}>
        <Link className={styles.navLink} to={"../films"}>
          <p>Films</p>
        </Link>

        <Link className={styles.navLink} to={"../books"}>
          <p>Books</p>
        </Link>
      </div>
      <div className={styles.search}>
        <input
          className={styles.searchBox}
          type="text"
          placeholder="Search..."
          onInput={(event) => setSearchTerm(event.target.value)}
        />
        <input
          className={styles.submit}
          type="submit"
          value="Search"
          onClick={() => {
            setBookSearchResults([]);
            setFilmSearchResults([]);
            getSearchResults(searchTerm.toLowerCase());
            navigate("search-results");
          }}
        />
      </div>
    </section>
  );
};

export default NavBar;
